import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import apiClient from "./FetchCliecnt";

// Define the state interface
interface AuthState {
  isAuthenticated: boolean;
  isOtpValid: boolean;
  redirectToHome: boolean;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// Initial state
const initialState: AuthState = {
  isAuthenticated: localStorage.getItem("isAuthenticated") === "true",
  isOtpValid: false,
  redirectToHome: false,
  status: 'idle',
  error: null,
};


export const sendOtp = createAsyncThunk(
  "auth/sendOtp",
  async (email: string, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/forgetUserPassword`,
        { email }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data.message);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const validateOtp = createAsyncThunk(
  "auth/validateOtp",
  async ({ email, otp }: { email: string; otp: string }, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/admin/verifyOTPForgetUserPassword`,
        { email, otp }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data.message);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async ({ email, newPassword, confirmPassword }: { email: string; newPassword: string; confirmPassword: string }, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/admin/forgetUserPasswordUpdate`,
        { email, new_password: newPassword, confirm_password: confirmPassword }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data.message);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const userSignup2 = createAsyncThunk(
  "auth/userSignup2/submit",
  async (body: any, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/user`,
        body
      );
      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const userLogin2 = createAsyncThunk(
  "auth/userLogin2/verifyOtp",
  async (body: any, thunkAPI) => {
    console.log(body,"body")
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/login`,
        body
      );
      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);
export const loginlogout = createAsyncThunk(
  "auth/userLogin2/logInLogout",
  async (body: any, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/postLoginLogoutTime`,
        body
      );
      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.isAuthenticated = true;
      localStorage.setItem("isAuthenticated", "true");
    },
    logout: (state) => {
      state.isAuthenticated = false;
      localStorage.setItem("isAuthenticated", "false");
      localStorage.removeItem("userInfo");
    },
    clear: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOtp.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendOtp.fulfilled, (state) => {
        state.status = 'succeeded';
        state.isOtpValid = false;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(validateOtp.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(validateOtp.fulfilled, (state) => {
        state.status = 'succeeded';
        state.isOtpValid = true;
      })
      .addCase(validateOtp.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(updatePassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.status = 'succeeded';
        state.redirectToHome = true;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(userSignup2.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userSignup2.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(userSignup2.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(userLogin2.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userLogin2.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(userLogin2.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { login, logout, clear } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
