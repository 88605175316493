import React, { useEffect, useState } from "react";
import "../../components/login/login.css";
import emailIcon from "../../assets/Images/user.png";
import passwordImage from "../../assets/Images/password.png";
import eyeopen from "../../assets/Images/eyeopen.svg";
import eyeclose from "../../assets/Images/eyeclosed.svg";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hook";
import { login, loginlogout, userLogin2 } from "../../Reducers/authSlice";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup"; // Import yup for validation
import { Loader } from "../loader/Loader";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";
import { ErrorMessage, Field, Form, Formik } from "formik";

interface SignupFormValues {
  email: string;
  password: string;
  isAdminPanel: number
}
function Login() {
  const dispatch = useDispatch<any>();
  const auth = useAppSelector((state: any) => state.auth);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(true);
  };

  const toggleHidePassword = () => {
    setShowPassword(false);
  };

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address.")
      .max(50, "Email should not exceed 50 characters.")
      .required("Email is required."),
    password: Yup.string()
      .min(6, "Password should be at least 6 characters.")
      .max(16, "Password should not exceed 16 characters.")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character."
      )
      .required("Password is required."),
  });
  const defaultValue: SignupFormValues = {
    email: "",
    password: "",
    isAdminPanel: 0
  };
  const handleverify = (values: SignupFormValues) => {
    const { email, password } = values;

    const body: any = {
      email: email,
      password: password,
      isAdminPanel : 0
    };

    setLoading(true);


    dispatch(userLogin2(body))
      .unwrap()
      .then((res: any) => {
        localStorage.setItem("userInfo", JSON.stringify(res.userInfo));
        dispatch(login())
        const loginlogoutData = {
          type: res.userInfo.role,
          user_id: res.userInfo.id,
          firstname: res.userInfo.firstname,
          lastname: res.userInfo.lastname,
          email: res.userInfo.email
        };
        dispatch(loginlogout(loginlogoutData));

        //Cookies.set("loginTime", new Date().getTime().toString(), { expires: 1 });
        
        const redirectUrl = localStorage.getItem("redirectUrl");

        Swal.fire({
          icon: "success",
          title: "Login Successfully",
        });
        navigate("/");
      })
      .catch((error: any) => {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    dispatch(setMobileHeadingData("Login"));
    dispatch(resetAllCardData());
  }, [dispatch]);

  return (
    <>
      <div className="container backgroundblur">
        <Formik
          initialValues={defaultValue}
          validationSchema={validationSchema}
          onSubmit={handleverify}
        >
          <Form className="form-container">
            <h2 className="lgn-heading">Login</h2>
            <hr />
            <div className="form-filed">
              <div className="form_filed-img">
                <img
                  src={emailIcon}
                  className="input1-icon user1-icon"
                  alt="Email Icon"
                />
              </div>
              <Field
                className="regi-input-signup"
                type="email"
                id="email"
                name="email"
                placeholder="Email Id"
              />

            </div>          
          <div className="text-danger field-errorMessage mb-3">
            <ErrorMessage name="email" />
          </div>
          <div className="form-filed">
            <div className="form_filed-img">
            <img
              src={passwordImage}
              className="input1-icon user1-icon"
              alt="Lock Icon"
            />
            </div>
            
            <Field
              className="regi-input-signup"
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Password"
            />
            <div className="eye-icon-container">
              {showPassword ? (
                <img
                  src={eyeopen}
                  className="eye-icon-size"
                  onClick={toggleHidePassword}
                />
              ) : (
                <img
                  src={eyeclose}
                  className="eye-icon-size"
                  onClick={toggleShowPassword}
                />
              )}
            </div>
          </div>
          <p className="text-danger field-errorMessage mb-3">
            <ErrorMessage name="password" />
          </p>
          <div className="forgot-password">
            <Link
              to="/forget"
              className="user_link"
              style={{ fontWeight: 700 }}
            >
              Forgot Password?
            </Link>
          </div>
          <div className="lgn-btn">
            <button type="submit">Login</button>

            {loading && <Loader />}
          </div>
          <div className="user text-center mt-4 userset">
            <Link to="/signup" className="user_link">
              New User?
            </Link>
          </div>
        </Form>
      </Formik>
    </div >
    </>
  );
}

export default Login;


