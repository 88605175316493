import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import apiClient from "./FetchCliecnt";


interface DetailState {
  status: 'idle' | 'loading' | 'error';
  error: string;
  DetailData: any[];
  RelatedData: any[];
  id: string | null;
}

const initialState: DetailState = {
  status: "idle",
  error: "",
  DetailData: [],
  RelatedData: [],
  id: null, // Initialize with null
};

export const detailData = createAsyncThunk(
  "detail/detail",
  async (params: { id: string }) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/admin/get-internal-newsById`,
        { id: params.id }
      );
      if (response.data.success) {
        return { data: response.data.getData, id: params.id };
      } else {
        throw new Error('Failed to fetch regulatory data');
      }
    } catch (error: any) {
      return { success: false, error: error.message, id: params.id };
    }
  }
);

export const relatedData = createAsyncThunk(
  "detail/getRelatedData",
  async (body: any, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/getRelatedData`,
        body
      );
      if (response.data.success) {    
        return response.data.getData;
      } else {
        throw new Error('Failed to fetch related data');
      }
    }  catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

const detailsSlice = createSlice({
  name: "detail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(detailData.pending, (state, action) => {
        state.status = 'loading';
        state.error = "";
        state.id = action.meta.arg.id;
      })
      .addCase(detailData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = "";
        state.DetailData = [action.payload.data];
        state.id = action.payload.id;
      })
      .addCase(detailData.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message ?? "Unknown error";
        state.id = action.meta.arg.id;
      });

      builder
      .addCase(relatedData.pending, (state, action) => {
        state.status = 'loading';
        state.error = "";
      })
      .addCase(relatedData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = "";
        state.RelatedData = action.payload
      })
      .addCase(relatedData.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message ?? "Unknown error";
      });
  },
});

export default detailsSlice.reducer;
