import React from 'react'

const PostedDate = ({date}:{date:any}) => {

    if (!date) return null
    const newDate = new Date(date);
    const day = String(newDate.getDate()).padStart(2, '0');
    const month = newDate.toLocaleString("en-US", { month: "short" })
    const year = newDate.getFullYear();
    return <span>{`${day} ${month}, ${year}`}</span>

}

export default PostedDate
