import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "./FetchCliecnt";
import type { RootState } from "../store";

interface HomeState {
    status: 'idle' | 'loading' | 'error';
    error: string;
    HomeData: any[]; 
  }
  
  const initialState: HomeState = {
    status: "idle",
    error: "",
    HomeData: [],
  };


  export const homeData = createAsyncThunk(
    "home/home",
    async () => {
      console.log('Fetching about us page...');
      try {
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/getHomeScreenData`,
        );
        if (response.data.success) {
          const filteredData = response.data.data.filter((news: any) => news.status === 'active');
          return filteredData;
        } else {
          throw new Error('Failed to fetch about us page');
        }
      } catch (error: any) {
        return { success: false, error: error.message }; 
      }
    }
  );

  const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(homeData.pending, (state) => {
          state.status = 'loading'; 
          state.error = ""; 
        })
        .addCase(homeData.fulfilled, (state, action) => {
          state.status = 'idle';
          state.error = "";
          state.HomeData = action.payload; 
        })
        .addCase(homeData.rejected, (state, action) => {
          state.status = 'error'; 
          state.error = action.error.message ?? "Unknown error"; 
        });
    },
  });
  
  export default homeSlice.reducer;
  