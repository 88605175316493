import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { detailData } from "../../Reducers/detailsSlice";
import { Loader } from "../loader/Loader";
import "../../components/news/news.css";
import { setPgcoloreMore, setPillBgColor, setShow } from "../../Reducers/pillBgColorSlice";
import { handleTabClick } from "../filterFunction";
import { setFillerArray } from "../../Reducers/fillerArraySlice";
import { fetchBusinessSubDataMobile, fetchGmpSubDataMobile, fetchNewsSubDataMobile, fetchProductSubDataMobile } from "../../Reducers/allSubcategorySlice";
import { businessData } from "../../Reducers/businessSlice";
import { gmpTrainingData } from "../../Reducers/gmpTrainingSlice";
import { productData } from "../../Reducers/productSlice";
import { setAllData } from "../../Reducers/allDataSlice";
import { AiOutlineDown } from "react-icons/ai";
import { AiOutlineUp } from "react-icons/ai";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";
import { categoryData } from "../../Reducers/allCategorySlice";
import { getSearchData } from "../../Reducers/searchSlice";
import { Search, TimeToLeave } from "@mui/icons-material";
import PostedDate from "../posteddate/PostedDate";

interface SearchValue {
  title: string;
}
function SearchPage() {
  const location = useLocation();
  const { selectedNews, prevPage } = location.state || {};
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const pillBgColor = useSelector((state: RootState) => state.pillBgColor);
  const show = useSelector((state: RootState) => state.pillBgColor.show);
  const { fillerArray } = useSelector((state: RootState) => state.fillerArray);
  const { ProductSubdata } = useSelector((state: RootState) => state.productCard);
  // const { ProductData, status, error } = useSelector((state: RootState) => state.product);
  const { CategoryData } = useSelector((state: RootState) => state.allCategory);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const pgcoloreMore = useSelector((state: RootState) => state.pillBgColor.pgcoloreMore);
  const [showDropdown, setShowDropdown] = useState(false);
  const { searchData, status, error } = useSelector((state: RootState) => state.search);

  // const onTabClick = (tab: string, subCategoryName?: string) => {
  //   handleTabClick({
  //     tab,
  //     subCategoryName,
  //     data: ProductData,
  //     dispatch,
  //   });
  //   dispatch(setShow(!!subCategoryName));
  // };

  // const handleDoubleClick = () => {
  //   setPillBgColor({});
  // };

  const handleCardClick = (id: string, prevPage: string) => {
    const selectedNews = searchData;
    dispatch(detailData({ id })); // Dispatch detailData with the id
    navigate(`/details/${id}`, { state: { selectedNews, prevPage } });
  };

  const handleCardClick2 = (url: string) => {
    window.open(url, '_blank');
  };

  const handleMoreClick = () => {
    setShowDropdown(!showDropdown);
    dispatch(setPgcoloreMore(true));
  };

  const handleLogoLink = (url: string) => {
    window.open(url, '_blank');
  };
  useEffect(() => {
    // dispatch(setMobileHeadingData("Search"))
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(getSearchData({ title: 'query' })).unwrap();
        dispatch(setMobileHeadingData("Search"));
        setLoading(false);

      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="container backgroundblur">
        <div className="mainHeadAbout">
          {/* <span>{CategoryData?.Category_E}</span> */}
          <span> Search</span>
        </div>

        <div className="row g-3" style={{ marginTop: "0px" }}>
          {searchData?.length > 0 ? (
            <>
              {searchData?.map((data: any) => (
                data.status === "active" ? (
                  <div className="col-lg-3 col-sm-6 col-md-6 All-col" key={data._id}>
                    <div
                      className="card custom-border-shadow-News"
                      style={{ cursor: "pointer", height: "100%" }}
                      onClick={() => {
                        if (data.name === "Internal News" || data.name === "Internal Product" || data.name === "Internal GMP" || data.name === "Internal Regulatory" || data.name === "Internal Business" || data.name === "Internal Videos") {
                          handleCardClick(data._id, prevPage);
                        } else {
                          handleCardClick2(data.link);
                        }
                      }}
                    >
                      <div className="custom-img-parent-News card-box">
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`}
                          className="card-img-top-news div-height"
                          alt="..."
                        />
                        <div className="image-lineae-gradient"></div>
                        <div
                          className="img-absolute-News"
                          onClick={() => {
                            if (data.logo_link) {
                              handleLogoLink(data.logo_link);
                            }
                          }}
                        >

                          {data.logo_image.length > 0 ? (
                            <div className="mob-hide">
                              <img
                                src={`${process.env.REACT_APP_API_URL}/${data.logo_image[0]?.src}`}
                                alt=""
                                className="src leftImg"
                              />
                            </div>
                          ) : <div className="mob-hide empty"></div>}

                          <div className="newschanel">
                            <span className="text-light custom-mobile-view-news">
                              {data.logo_title &&
                                data.logo_title.split(' ').slice(0, 2).join(' ').toUpperCase()}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="custom-card-body-news">
                        <p className="card-text for-custom-card-text-news card_content-news mb-0">
                          {data.title}
                        </p>
                        <span className="custom-text"> <PostedDate date={data?.created_at} /></span>
                      </div>
                    </div>
                  </div>
                ) : null
              ))}
            </>
          ) : (
            <div className="text-center mt-4 not-found-div">
              <p>No data found</p>
            </div>
          )}
        </div>

      </div>
    </>
  );
}

export default SearchPage;