import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "./FetchCliecnt";
import type { RootState } from "../store";

interface GmpTrainingState {
  status: 'idle' | 'loading' | 'error';
  error: string;
  GmpTrainingData: any[]; 
}

const initialState: GmpTrainingState = {
  status: "idle",
  error: "",
  GmpTrainingData: [],
};

export const gmpTrainingData = createAsyncThunk(
    "gmptraining/gmptraining",
    async () => {
      //console.log('Fetching about us page...');
      try {
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/All-GMP-active`,
        );
        if (response.data.success) {
          const filteredData = response.data.data.filter((news: any) => news.status === 'active');
          return filteredData;
        } else {
          throw new Error('Failed to fetch about us page');
        }
      } catch (error: any) {
        return { success: false, error: error.message }; 
      }
    }
  );

const gmpTrainingSlice = createSlice({
    name: "about",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(gmpTrainingData.pending, (state) => {
          state.status = 'loading'; 
          state.error = ""; 
        })
        .addCase(gmpTrainingData.fulfilled, (state, action) => {
          state.status = 'idle';
          state.error = ""; 
          state.GmpTrainingData = action.payload; 
        })
        .addCase(gmpTrainingData.rejected, (state, action) => {
          state.status = 'error'; 
          state.error = action.error.message ?? "Unknown error"; 
        });
    },
  });
  

export const userList = (state: RootState) => state.auth;
export default gmpTrainingSlice.reducer;
