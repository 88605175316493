import React, { useState } from 'react'
import vectorImage from "../../assets/Images/Vector (1).png";
import emailIcon from "../../assets/Images/user.png";
import passwordImage from "../../assets/Images/password.png";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../components/profile/profile.css";
import EditPassword from "../../components/popup/EditPassword";
import { Loader } from "../loader/Loader";

import Swal from 'sweetalert2';
import { profileDataUpdate } from '../../Reducers/profileSlice';
import { useDispatch } from 'react-redux';

interface ProfileFormValues {
    fullname: string;
    email: string
}
const Profile = () => {
    const dispatch = useDispatch<any>();
    const [loading, setLoading] = useState(false);
    const loginDetail = localStorage.getItem("userInfo");
    const userInfo = loginDetail ? JSON.parse(loginDetail) : {};
    const defaultValue = {
        fullname: `${userInfo.firstname || ""} ${userInfo.lastname || ""}`.trim(),
        email: userInfo.email || "",
    };

    const validationSchema = Yup.object({
        fullname: Yup.string()
            .matches(/^[A-Za-z\s]+$/, "Full name should only contain letters and spaces.")
            .max(50, "Full name should not exceed 50 characters.") // Full name validation
            .required("Full name is required."),
        email: Yup.string()
            .email("Invalid email address.")
            .max(50, "Email should not exceed 50 characters.")
            .required("Email is required."),
    });

    const handleSubmit = async (values: { fullname: string}) => {
        setLoading(true);
        const data = { id: userInfo.id, fullname: values.fullname }; 
        try {
            const response = await dispatch(profileDataUpdate(data));                      
            if(response.payload){
                Swal.fire({
                    icon: "success",
                    title: "Profile updated successfully.",
                });    
                localStorage.setItem("userInfo",JSON.stringify(response.payload.data.data));            
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                })
            }          
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Profile update failed.",
            });
        }
        finally {
            setLoading(false);
        }
    }
    return (
        <div className="container backgroundblur">
            <Formik
                initialValues={defaultValue}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <Form className='Form-field-parent float'>
                    <div className='d-flex justify-content-between mb-2 align-items-baseline'>
                        <h2 className="sgnup-heading">Profile</h2>
                        {/* <hr />  */}
                        <button type='button' className='btn btn-primary edit-psd-btn' data-bs-toggle="modal" data-bs-target="#exampleModal">Change Password</button>
                    </div>

                    <div className='form-filed'>
                        <div className="form_filed-img">
                            <img src={vectorImage} className="input1-icon user1-icon" alt="Person Icon" />
                        </div>
                        <Field
                            className="regi-input-signup"
                            type="text"
                            id="fullname"
                            name="fullname"
                            placeholder="Full Name"
                        />

                    </div>
                    <div className="text-danger field-errorMessage mb-3">
                        <ErrorMessage name="fullname" />
                    </div>
                    <div className='form-filed'>
                        <div className="form_filed-img">
                            <img src={emailIcon} className="input1-icon user1-icon" alt="Email Icon" />
                        </div>
                        <Field
                            className="regi-input-signup"
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email Id"
                            disabled
                        />
                        <div className="text-danger field-errorMessage mb-3">
                            <ErrorMessage name="email" />
                        </div>
                    </div>
                    <div className="btn-profile">
                        <button type="submit" className="btn btn-primary btn-submit">Submit</button>
                    </div>
                </Form>
            </Formik>
            {loading && <Loader />}
            <EditPassword />
        </div>
    )
}

export default Profile
