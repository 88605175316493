import React, { useEffect, useState } from "react";
import HomeImg from "../../assets/Images/Rectangle310.png";
import "../../components/details/details.css";
import PPtImg from "../../assets/Images/ppt-img.png";
import Doctor2 from "../../assets/Images/Group7262.png";
import newsImg from "../../assets/Images/image126.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Markdown from 'markdown-to-jsx'
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { detailData, relatedData } from "../../Reducers/detailsSlice";
import { Loader } from "../loader/Loader";
import ReactPlayer from 'react-player';
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";
import { Height } from "@mui/icons-material";
import PostedDate from "../posteddate/PostedDate";


function Details() {

  const location = useLocation();
  const { selectedNews, prevPage } = location.state || {};
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const swiperParams = {
    slidesPerView: 1.5,
    loop: true,
    spaceBetween: 10,
    breakpoints: {
      480: {
        slidesPerView: 1.5,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
  };


  const swiperParams2 = {
    slidesPerView: 1.5,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    loop: true,
    spaceBetween: 25,
    breakpoints: {
      480: {
        slidesPerView: 3,
        spaceBetween: -2,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: -2,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: -2,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: -2,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1400: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const truncateContent = (content: string, length: number) => {
    if (content.length <= length) {
      return content;
    }

    // Find the last space within the desired length
    const truncated = content.substring(0, length);
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    // If there's no space, truncate normally
    if (lastSpaceIndex === -1) {
      return `${truncated}`;
    }

    // Truncate at the last space and add ellipsis
    return `${truncated.substring(0, lastSpaceIndex)}`;
  };


  const handleCardClick = (id: string, prevPage: string) => {
    dispatch(detailData({ id })); // Dispatch detailData with the id
    navigate(`/details/${id}`, { state: { selectedNews, prevPage } });  // Navigate to details page
  };

  const handleCardClick2 = (url: string) => {
    window.open(url, '_blank');
  };
  const handleLogoLink = (url: string) => {
    window.open(url, '_blank');
  };

  const dispatch = useDispatch<AppDispatch>();

  const { DetailData, RelatedData } = useSelector((state: RootState) => state.detailPage);

  // console.log(DetailData, "detail");
  // console.log(RelatedData,"related data");
  // console.log(DetailData[0]?.pdfurl, "DetailDataDetailData");

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {

    dispatch(setMobileHeadingData(prevPage))
    setLoading(true);

    const fetchData = async () => {
      if (id) {
        const response = await dispatch(detailData({ id })).unwrap();
        const related_Data = {
          category_name: response.data.category_name,
          title: response.data.title
        };
        console.log(related_Data, "related param")
        dispatch(relatedData(related_Data));
      }
      setLoading(false);
    };

    fetchData();
  }, [dispatch, id]);

  const filterRelatedData = RelatedData?.filter((newsItem: any) =>
    !DetailData?.some((detailItem: any) => detailItem._id === newsItem._id));


  return (
    <>
      {loading && <Loader />}
      <div className="container backgroundblur">
        <div className="row mt-3 mb-3">
          {/* {DetailData.map((data: any, index: number) => ( */}
          {/* <div className="col-12 col-lg-9" key={index}> */}

          {DetailData?.map((data: any, index: number) => (
            <div className="col-12 col-lg-9">
              <div className="custom-line">
                <h6 className="text-dark first-head-details">
                  {data?.title}
                </h6>
                <span className="date-text">Posted on: <PostedDate date={data?.created_at} /></span>
                <div className="detail-img-div">
                  <img src={`${process.env.REACT_APP_API_URL}/${data?.news_image[0]?.src}`}
                    className="img-fluid mt-2 detailimg"
                    style={{ width: "100%", borderRadius: "15px", objectFit: "cover" }} /></div>
                <div className="first-para for-text-format">
                  <span>
                    <p className="para-detail" dangerouslySetInnerHTML={{ __html: data.news_content }}>
                    </p>
                  </span>

                </div>

                <div className="mt-2">
                  <Link
                    to={`${process.env.REACT_APP_API_URL}/${data?.news_file[0]?.src || ""}`}
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    className="detail-font detail-Link"
                  >
                    {data?.news_file?.length > 0 ? <i className="bi bi-filetype-pdf">&nbsp;</i> : ""} {data?.news_file[0]?.src}
                  </Link>
                </div>
                {/* <div className="video mt-4">
                  {data?.news_video[0]?.src ? (
                    <iframe
                      className="img-fluid detailimg"
                      src={`${process.env.REACT_APP_API_URL}/${data.news_video[0].src}`}
                      frameBorder="0"
                      allowFullScreen
                      title="Embedded YouTube Video"
                      style={{ height: "520px", width: "100%", paddingRight: "30px" }}
                    ></iframe>
                  ) : (
                    <p>Loading video...</p>
                  )}
                </div> */}

                <div className="video mt-4">
                  {/* {data?.news_video[0]?.src ? (
                    <ReactPlayer
                      className="img-fluid detailimg"
                      url={`${process.env.REACT_APP_API_URL}/${data.news_video[0].src}`}
                      controls
                      width="100%"
                      height="520px"
                      style={{ paddingRight: "30px" }}
                    />   
                  ) : (
                    <p>Loading video...</p>
                  )} */}

                  <div className="detail-img-div">
                    {data?.news_video[0]?.src ? (
                      (() => {
                        const fileSrc = data.news_video[0].src;
                        const fileExtension = fileSrc.split('.').pop().toLowerCase();


                        if (fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'avi') {
                          return (
                            <div className="video-viewer mt-4">
                              <iframe
                                className="img-fluid detailimg"
                                src={`${process.env.REACT_APP_API_URL}/${fileSrc}`}
                                width="100%"
                                height="520px"
                                style={{ borderRadius: "15px" }}
                                title="Video Presentation"
                              ></iframe>
                            </div>
                          );
                        } else if (fileExtension === 'pdf') {
                          return (
                            <div className="pdf-viewer mt-4">
                              <Link
                                to={`${process.env.REACT_APP_API_URL}/${fileSrc}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="detail-font detail-Link"
                              >
                                {data?.news_video?.length > 0 ? <i className="bi bi-filetype-pdf">&nbsp;</i> : ""} {data?.news_video[0]?.src}
                              </Link>
                            </div>
                          );
                        } else if (fileExtension === 'ppt' || fileExtension === 'pptx') {
                          return (
                            <div className="ppt-viewer mt-4">
                              <Link
                                to={`${process.env.REACT_APP_API_URL}/${fileSrc}`}
                                style={{ fontSize: "18px", fontWeight: "400" }}
                                className="detail-font detail-Link"
                              >
                                {data?.news_video?.length > 0 ? <i className="bi bi-filetype-pdf">&nbsp;</i> : ""} {data?.news_video[0]?.src}
                              </Link>
                            </div>
                          );
                        } else {
                          return "";
                        }
                      })()
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="parent-container mt-4"
                  style={{ height: data.pdfurl ? "100%" : "0" }}>
                  {data.pdfurl ? (
                    (data.pdfurl.startsWith("http://") || data.pdfurl.startsWith("https://")) ? (
                      <iframe
                        className="pdf-container"
                        src={data.pdfurl}
                        style={{ width: "100%", height: "500px", border: "none", borderRadius: "2px" }}
                      />
                    ) : (
                      null
                    )
                  ) : (
                    null
                  )}
                </div>
              </div>
            </div>
          ))}
          
          <div className="col-12 col-lg-3">
            <div className="related_parent">
              <h3 className="Related_news_details">Related News</h3>
            </div>

            {isMobile ? (
              <Swiper {...swiperParams}>
                {filterRelatedData?.slice(0, 6).map((data: any, index: number) => (
                  <SwiperSlide key={index} className="fixed-width-slide">
                    <div className="card custom-border-shadow" onClick={() => {
                      if (data.name === "Internal News" || data.name === "Internal Product" || data.name === "Internal GMP" || data.name === "Internal Regulatory" || data.name === "Internal Business" || data.name === "Internal Videos") {
                        handleCardClick(data._id, prevPage);
                      }
                      else {
                        handleCardClick2(data.link)
                      }
                    }}>
                      <div className="custom-img-parent">
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`}
                          className="card-img-top-details"

                        />
                        <div className="image-lineae-gradient-swiper"></div>
                        <div className="img-absolute"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (data.logo_link) {
                              handleLogoLink(data.logo_link);
                            }
                          }}>
                          <div>
                            {data.logo_image.length > 0 ? (
                              <img src={`${process.env.REACT_APP_API_URL}/${data.logo_image[0]?.src}`}
                                className="src" />
                            ) : (
                              ""
                            )}
                          </div>


                          <div className="newschanel">
                            <span className="text-light">{data.logo_title && data.logo_title.split(' ').slice(0, 2).join(' ').toUpperCase()}</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-body card-body-detail">
                        <p className="card-text card-text-detail mb-0">
                          {data.title}
                        </p>
                        <span className="custom-text"> <PostedDate date={data.created_at} /></span>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className="row">
                {filterRelatedData?.slice(0, 6).map((data: any, index: number) => (
                  <div className="col-12 col-md-6 col-lg-12" key={index}>
                    <div className="card custom-border-shadow cursor-pointer"
                      style={{ cursor: "pointer" }} onClick={() => {
                        if (data.name === "Internal News" || data.name === "Internal Product" || data.name === "Internal GMP" || data.name === "Internal Regulatory" || data.name === "Internal Business" || data.name === "Internal Videos") {
                          handleCardClick(data._id, prevPage);
                        }
                        else {
                          handleCardClick2(data.link)
                        }
                      }}>
                      <div className="custom-img-parent">
                        <img src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`}
                          className="card-img-top" />
                        <div className="image-lineae-gradient"></div>
                        <div className="img-absolute">
                          <div className="leftImg-detail">
                            {data.logo_image.length > 0 ? (
                              <img src={`${process.env.REACT_APP_API_URL}/${data.logo_image[0]?.src}`} className="src" />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="newschanel">
                            <span className="text-light"> {data.logo_title && data.logo_title.split(' ').slice(0, 2).join(' ').toUpperCase()}</span>
                          </div>
                        </div>
                      </div>
                      <div className="custom-card-body-news">
                        <p className="card-text card-text-detail mb-0">
                          {data.title}
                        </p>
                        <span className="custom-text"> <PostedDate date={data.created_at} /></span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>);
}

export default Details;
