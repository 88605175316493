import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "./FetchCliecnt";
import type { RootState } from "../store";

interface AboutState {
  status: 'idle' | 'loading' | 'error';
  error: string;
  AboutUsData: any[]; 
}

const initialState: AboutState = {
  status: "idle",
  error: "",
  AboutUsData: [],
};

export const aboutUsData = createAsyncThunk(
    "about/aboutUs",
    async () => {
      //console.log('Fetching about us page...');
      try {
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/admin/all-about-us`,
        );
  
        if (response.data.success) {
          return response.data; 
        } else {
          throw new Error('Failed to fetch about us page');
        }
      } catch (error: any) {
        return { success: false, error: error.message }; 
      }
    }
  );

const aboutUsSlice = createSlice({
    name: "about",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(aboutUsData.pending, (state) => {
          state.status = 'loading'; 
          state.error = ""; 
        })
        .addCase(aboutUsData.fulfilled, (state, action) => {
          state.status = 'idle';
          state.error = ""; 
          state.AboutUsData = action.payload.data; 
        })
        .addCase(aboutUsData.rejected, (state, action) => {
          state.status = 'error'; 
          state.error = action.error.message ?? "Unknown error"; 
        });
    },
  });
  

export const userList = (state: RootState) => state.auth;
export default aboutUsSlice.reducer;
