
import "../../components/home/home.css";
import { Link, useNavigate } from "react-router-dom";
import Group3148 from "../../assets/Images/Group3148.png";
import Group3149 from "../../assets/Images/Group3149.png";
import Group3150 from "../../assets/Images/Group3150.png";
import Image174 from "../../assets/Images/image174.jpg";
import Group3151 from "../../assets/Images/Group3151.png";
import Group3152 from "../../assets/Images/Group3152.png";
import Group3153 from "../../assets/Images/Group3153.png";
import Group3154 from "../../assets/Images/Group3154.png";
import Group3155 from "../../assets/Images/Group3155.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import { Pagination, Autoplay } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { detailData } from "../../Reducers/detailsSlice";
import { Loader } from "../loader/Loader";
import { Height } from "@mui/icons-material";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";
import { homeData } from '../../Reducers/homeSlice';
import { log } from "console";
import PostedDate from "../posteddate/PostedDate";


const Home = () => {

  const swiperParams2 = {
    slidesPerView: 1.5,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    loop: true,
    spaceBetween: 25,
    breakpoints: {
      480: {
        slidesPerView: 3,
        spaceBetween: -2,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: -2,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: -2,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: -2,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 17,
      },
      1400: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };


  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { HomeData, status, error } = useSelector((state: RootState) => state.home);
  const filterHomeData = Array.isArray(HomeData) ? HomeData.slice(0, 20) : [];


  const handleCardClick = (id: string) => {
    const selectedNews = HomeData;
    dispatch(detailData({ id }));
    navigate(`/details/${id}`, { state: { selectedNews } });
  };

  const handleCardClick2 = (url: string) => {
    window.open(url, '_blank');
  };

  const handleLogoLink = (url: string) => {
    window.open(url, '_blank');
  };
  useEffect(() => {
    setLoading(true);
    dispatch(setMobileHeadingData("Home"))
    dispatch(resetAllCardData());
    dispatch(homeData())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <>
      {loading && <Loader />}
      <div className="container backgroundblur">

        <div className="row mb-3 mt-4 g-3 custom-margin-home allNewsbox">

          {filterHomeData?.map((data: any, index: number) => (
            data.status === "active" ? (
              <div className="col-lg-3 col-sm-6 col-md-6 All-col myheight" key={data._id}>
                <div
                  className="card custom-border-shadow-News"
                  style={{ cursor: "pointer", height: "100%" }}
                  onClick={() => {
                    if (data.name === "Internal News" || data.name === "Internal Product" || data.name === "Internal GMP" || data.name === "Internal Regulatory" || data.name === "Internal Business" || data.name === "Internal Videos") {
                      handleCardClick(data._id);
                    } else {
                      handleCardClick2(data.link);
                    }
                  }}
                >
                  <div className="custom-img-parent-News card-box">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`}
                      className="card-img-top-news"
                      alt="hiiiii"
                    />
                    <div className="image-lineae-gradient"></div>
                    <div className="img-absolutes-News"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (data.logo_link) {
                          handleLogoLink(data.logo_link);
                        }
                      }}>

                      {data.logo_image.length > 0 ? (
                        <div className="mob-hide">
                          <img
                            src={`${process.env.REACT_APP_API_URL}/${data.logo_image[0]?.src}`}
                            alt="hii"
                            className="srcin leftImg"
                          />
                        </div>
                      ) : (                       
                        <div className="mob-hide empty"></div>
                      )}

                      <div className="newschanel">
                        <span className="text-light custom-mobile-view-news">
                          {data.logo_title && data.logo_title.split(" ").slice(0, 2).join(" ").toUpperCase()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="custom-card-body-news">
                    <p className="card-text for-custom-card-text-news card_content-news mb-0">
                      {data.title}
                    </p>
                    <span className="custom-text"> <PostedDate date = {data?.created_at}/></span>
                  </div>
                </div>
              </div>
            ) : null
          ))}
        </div>
        
        <div className="company-logo mt-3">
          <div className="row g-3">
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3148} alt="company-logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3149} alt="company-logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3150} alt="company-logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3151} alt="company-logo" />
              </div>
            </div>
          </div>
          <div className="row g-3 pdRow">
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3152} alt="company-logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3153} alt="company-logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3154} alt="company-logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3155} alt="company-logo" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Home;
