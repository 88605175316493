import { Link, To, useLocation, useNavigate } from "react-router-dom";
import "../../components/header/header.css";
import { useEffect, useState } from "react";
import Facebookpng from "../../assets/Images/facebook.png";
import Instgrampng from "../../assets/Images/Vector.png";
import Linkdinpng from "../../assets/Images/linkedin.png";
import MicrosoftTeams from "../../assets/Images/Pharamaimg.jpg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { login, logout } from "../../Reducers/authSlice";
import { AiFillHome } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { BsRecord } from "react-icons/bs";
import { TfiMoreAlt } from "react-icons/tfi";
import { Loader } from "../loader/Loader";
import axios from 'axios';
import { TfiMenu } from "react-icons/tfi";
import { AiOutlineMenu } from "react-icons/ai";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { css } from "@emotion/react";
import { fetchBusinessSubData, fetchProductSubData, fetchGmpSubData, fetchRegulatorySubData, fetchVideoSubData, fetchNewsSubData } from "../../Reducers/cardSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";
import { categoryData } from "../../Reducers/allCategorySlice";
import { handleTabClick } from "../filterFunction";
import { setPgcoloreMore, setShow } from "../../Reducers/pillBgColorSlice";
import { newsData } from "../../Reducers/newsSlice";
import { gmpTrainingData } from "../../Reducers/gmpTrainingSlice";
import { regulatoryData } from "../../Reducers/regulatorySlice";
import { productData } from "../../Reducers/productSlice";
import { businessData } from "../../Reducers/businessSlice";
import { getSearchData } from "../../Reducers/searchSlice";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";

function Header() {

  const loaction = useLocation();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isExampleOpen, setIsExampleOpen] = useState(false);
  const [webSearch, setwebSearch] = useState(false);
  const [mobSearch, setmobSearch] = useState(false);
  const [isOpenbarOpen, setIsOpenbarOpen] = useState(false);
  const mobileHeadingData = useSelector((state: RootState) => state.mobileHeading.mobileHeadingData);
  const [temperature, setTemperature] = useState(null);
  const [isContainerVisible, setIsContainerVisible] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const VideoData = useSelector((state: RootState) => state.video.VideoData);
  const pillBgColor = useSelector((state: RootState) => state.pillBgColor);



  const handleSearch = (query: string) => {
    if (!query.trim()) {
      return;
    }
    setLoading(true);
    setmobSearch(true); 
    setwebSearch(true);
    clearSearchAndBlur();
    dispatch(getSearchData({ title: query })).then((res) => {
      //console.log(res, "data res");
      setLoading(false);
    })

    navigate('/search');
  };


  const clearSearchAndBlur = () => {
    const backgroundBlur = document.querySelector(".backgroundblur");
    const searchInput = document.querySelector("#searchBar");
    if (backgroundBlur) {
      backgroundBlur.classList.remove("blur-background"); // Remove the background blur
    }
    if (searchInput) {
      searchInput.classList.remove("show"); // Hide search input
    }
    setSearchQuery(""); // Clear the search query
    setwebSearch(false); // Close web search
    setmobSearch(false); // Close mobile search
  };


  const handleIconClick = (path: To) => {
    // Navigate to the specified path and reset everything
    navigate(path);
    clearSearchAndBlur();
  };

  const onTabClick = (tab: string, subCategoryName?: string, data?: any) => {
    handleTabClick({
      tab,
      subCategoryName,
      data,
      dispatch,
    });
    dispatch(setShow(!!subCategoryName));
  };

  const toggleContainerVisibility = () => {
    setIsContainerVisible((prevState) => !prevState);
  };


  const toggleSearch = () => {
    setmobSearch(!mobSearch);
    setIsOpenbarOpen(false);
    const backgroundblur = document.querySelector(".backgroundblur");
    if (backgroundblur) {
      backgroundblur.classList.toggle("blur-background");
    }
  };
  const navigate = useNavigate();
  const toggleOpenbar = () => {
    if (!isOpenbarOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    setIsOpenbarOpen(!isOpenbarOpen);
    setmobSearch(false);
  };
  const toggleWebcoll = () => {
    setIsExampleOpen(!isExampleOpen);
    setwebSearch(false);
  };
  const toggleWebSearch = () => {
    setwebSearch(!webSearch);
    setIsExampleOpen(false);
    const backgroundblur = document.querySelector(".backgroundblur");
    if (backgroundblur) {
      backgroundblur.classList.toggle("blur-background");
    }
    if (!webSearch) {
      setSearchQuery(""); // Clear search query when closing web search
    }
  };

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const { BusinessSubData, status, error } = useSelector((state: RootState) => state.businessCard);
  const { ProductSubdata } = useSelector((state: RootState) => state.productCard);
  const { GmpSubdata } = useSelector((state: RootState) => state.gmpCard);
  const { RegulatorySubdata } = useSelector((state: RootState) => state.regulatoryCard);
  const { VideoSubdata } = useSelector((state: RootState) => state.videoCard);
  const { NewsSubData } = useSelector((state: RootState) => state.newsCard);
  const { CategoryData } = useSelector((state: RootState) => state.allCategory);

  const { GmpTrainingData } = useSelector((state: RootState) => state.gmptraining);
  const { NewsData } = useSelector((state: RootState) => state.news);
  const { BusinessData } = useSelector((state: RootState) => state.business);
  const { ProductData } = useSelector((state: RootState) => state.product);
  const { RegulatoryData } = useSelector((state: RootState) => state.regulatory);

  const { AlldataData } = useSelector((state: RootState) => state.alldata);
  const handleLoginClick = () => {
    // dispatch(login());
    navigate("/login");

  };

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleLogoutClick = () => {
    dispatch(logout());
    navigate("/login");
  };
  const handleSignupbutton = () => {
    navigate('/signup');
  };
  const handleProfilebutton = () => {
    navigate('/profile');
  };


  const formatDate = (date: Date | null | undefined): string => {
    if (!date) {
      return ''; // Handle case where date is null or undefined
    }

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const day = days[date.getDay()];
    const month = months[date.getMonth()];
    const dateNum = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return `${day}, ${dateNum} ${month}, ${year} | ${hours}:${minutes} ${ampm}`;
  };





  // Function to fetch the current temperature for Indore
  // const fetchTemperature = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.openweathermap.org/data/2.5/weather?q=Indore&units=metric&appid=YOUR_API_KEY`
  //     );
  //     setTemperature(response.data.main.temp);
  //   } catch (error) {
  //     console.error("Error fetching temperature:", error);
  //   }
  // }


  const { AllCarddata } = useSelector((state: RootState) => state.allSubCategory);


  // Update localStorage whenever changeHead state changes



  useEffect(() => {
    dispatch(fetchBusinessSubData());
    dispatch(fetchNewsSubData());
    dispatch(fetchProductSubData());
    dispatch(fetchGmpSubData());
    dispatch(fetchRegulatorySubData());
    dispatch(fetchVideoSubData())
    // fetchTemperature();
    dispatch(resetAllCardData());
    dispatch(categoryData())
    dispatch(newsData())
    dispatch(gmpTrainingData())
    dispatch(regulatoryData())
    dispatch(productData())
    dispatch(businessData())

  }, [dispatch]);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);

  }, []);






  return (
    <>
    {loading && <Loader />}
      <div className="headerblur">
        <div className="container">
          <div
            id="topbar"
            className="d-flex align-items-center justify-content-between mob-hidden"
          >
            <div className="datetime">
              <span>{formatDate(currentDateTime)} | Indore</span>
              {/* <span>{formatDate(currentDateTime)} | Indore | {temperature ? `${temperature}°C` : "29°C"}</span> */}
            </div>
            <div className="text-md-right text-center">
              {isAuthenticated ? (
                <div className="d-inline">
                  <button className={`btn-custom ${location.pathname === '/profile' ? 'topbtn1bg' : 'topbtn2'}`}
                    onClick={handleProfilebutton}
                  >
                    Profile
                  </button>
                  <button
                    className="btn-custom topbtn2"
                    onClick={handleLogoutClick}
                  >
                    Log Out
                  </button>  
                </div>


              ) : (
                <button
                  className={`btn-custom  ${loaction.pathname === '/login' ? "topbtn1bg" : "topbtn1"}`}

                  onClick={handleLoginClick}
                >
                  Log In
                </button>
              )}

              {isAuthenticated ? (
                ""
              ) : (
                <button
                  className={`btn-custom ${location.pathname === '/signup' ? "topbtn1bg" : "topbtn2"}`}
                  onClick={handleSignupbutton}
                >
                  Sign Up
                </button>
              )}

              <Link to="/">
                <img src={Facebookpng} alt="Facebook Icon" className="ml-2" />
                {/* {loading && <Loader />} */}
              </Link>
              <Link to="/">
                <img src={Linkdinpng} alt="LinkedIn Icon" className="ml-2" />
                {/* {loading && <Loader />} */}
              </Link>
              <Link to="/" style={{ marginRight: "0px" }}>
                <img src={Instgrampng} alt="Instagram Icon" className="ml-2" />
                {/* {loading && <Loader />} */}
              </Link>
            </div>
          </div>
        </div>

        <div id="logo" className="container-fluid">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <Link to="/">
              <img src={MicrosoftTeams} alt="Microsoft Teams" />
            </Link>
          </div>
          <div className="col-md-3"></div>
        </div>

        <nav id="nav" className="navbar navbar-expand-lg">
          <div className="container">
            <ul className="navbar-nav ml-auto navul">

              <li className="nav-item">
                <Link className="nav-link" to="/" onClick={() => setIsExampleOpen(false)} style={{ paddingLeft: "0px", paddingBottom: "13px" }}>
                  <AiFillHome className="Home-icon" onClick={() => handleIconClick("/")} />
                </Link>
              </li>
              <li className="nav-item">




                <Link className="nav-link" to="/news" onClick={(e) => {
                  setIsExampleOpen(false); onTabClick("hello", undefined, NewsData);
                  setShow(false); handleIconClick("/news");
                }}  >
                  <span className={`nav-item ${location.pathname === "/news" ? "li-active-header" : ""
                    }`}>{CategoryData?.Category_A}</span>
                </Link>


              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/regulatory" onClick={() => {
                  setIsExampleOpen(false); onTabClick("hello", undefined, RegulatoryData);
                  setShow(false); handleIconClick("/regulatory")
                }}>
                  <span className={`nav-item ${location.pathname === "/regulatory" ? "li-active-header" : ""
                    }`}>{CategoryData?.Category_B}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/gmptraining" onClick={() => {
                  setIsExampleOpen(false); onTabClick("hello", undefined, GmpTrainingData);
                  setShow(false); handleIconClick("/gmptraining")
                }}>
                  <span className={`nav-item ${location.pathname === "/gmptraining" ? "li-active-header" : ""
                    }`}> {CategoryData?.Category_C}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/business" onClick={() => {
                  setIsExampleOpen(false); onTabClick("hello", undefined, BusinessData);
                  setShow(false); handleIconClick("/business")
                }}>
                  <span className={`nav-item ${location.pathname === "/business" ? "li-active-header" : ""
                    }`}>{CategoryData?.Category_D}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/product" onClick={() => {
                  setIsExampleOpen(false); onTabClick("hello", undefined, ProductData);
                  setShow(false); handleIconClick("/product")
                }}>
                  <span className={`nav-item ${location.pathname === "/product" ? "li-active-header" : ""
                    }`}> {CategoryData?.Category_E}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/video" onClick={() => {
                  setIsExampleOpen(false); onTabClick("hello", undefined, VideoData);
                  setShow(false); handleIconClick("/video")
                }}>
                  <span className={`nav-item ${location.pathname === "/video" ? "li-active-header" : ""
                    }`}>{CategoryData?.Category_F}</span>
                </Link>
              </li>


              <li className="nav-item">
                <Link className="nav-link" to="/aboutus" onClick={() => { setIsExampleOpen(false); handleIconClick("/aboutus") }}>
                  <span className={`nav-item ${location.pathname === "/aboutus" ? "li-active-header" : ""
                    }`}> About Us</span>
                </Link>
              </li>
              <li className={`nav-item ${isExampleOpen ? "opencard" : ""} `}>
                <Link className="nav-link" to="#" onClick={toggleWebcoll}>
                  {/* <AiOutlineMenu style={{ fontSize: '24px' }} /> */}
                  <TfiMenu className="all-hed-icon" onClick={() => handleIconClick("#")} />
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#" onClick={toggleWebSearch}>
                  {webSearch ? (
                    <i className="bi bi-x all-hed-icon"></i>
                  ) : (
                    <i className="bi bi-search all-hed-icon"></i>
                  )}
                </Link>
              </li>
            </ul>
          </div>
        </nav>


        <div className="container">
          <div style={{ position: "relative" }}
            className={`collapse  ${isExampleOpen ? "show" : ""}`}
            id="collapseExample"
          >
            <div className="card card-body container collcard">
              <div className="row cardRow">
                {[
                  { data: NewsSubData, category: CategoryData?.Category_A, onClick: onTabClick, navigate: "/news", dataNew: NewsData },
                  { data: RegulatorySubdata, category: CategoryData?.Category_B, onClick: onTabClick, navigate: "/regulatory", dataNew: RegulatoryData },
                  { data: GmpSubdata, category: CategoryData?.Category_C, onClick: onTabClick, navigate: "/gmptraining", dataNew: GmpTrainingData },
                  { data: BusinessSubData, category: CategoryData?.Category_D, onClick: onTabClick, navigate: "/business", dataNew: BusinessData },
                  { data: ProductSubdata, category: CategoryData?.Category_E, onClick: onTabClick, navigate: "/product", dataNew: ProductData }
                ].map((item, idx) => (
                  <div key={idx} className="col inner-box cardinner">
                    <span className="glyphicon glyphicon-chevron-right">
                      <AiOutlineRight style={{ marginRight: "15px" }} />
                    </span>
                    <div
                      className="card-heading"
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick("hello", undefined, item.dataNew);
                          setIsExampleOpen(false);
                          setShow(false);
                        }
                      }}
                    >
                      <Link to={item.navigate} className="card-link">
                        {item.category}
                      </Link>
                    </div>
                    <ul className="allulcard">
                      {item.data.slice(0,5).map((data, index) => (
                        <li key={index}>
                          {item.navigate ? (
                            <Link
                              to={item.navigate}
                              onClick={() => {
                                if (index > 4) {
                                  dispatch(setPgcoloreMore(true));
                                } else {
                                  dispatch(setPgcoloreMore(false));
                                }
                                if (item.onClick) {
                                  item.onClick(data._id, data.sub_category_name, item.dataNew);
                                  navigate(item.navigate);
                                  setIsExampleOpen(false);
                                  setShow(true);

                                }
                              }}
                            >
                              <BsRecord />
                              <span className="ultext">{data.sub_category_name}</span>
                            </Link>
                          ) : (
                            <>
                              <BsRecord />
                              <span className="ultext">{data.sub_category_name}</span>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                    {item.data.length > 5 && (
                     
                        <Link to={item.navigate} style={{color: "#247496"}}
                         onClick={() => {
                          if (item.onClick) {
                            item.onClick("hello", undefined, item.dataNew);
                            setIsExampleOpen(false);
                            setShow(false);
                          }
                        }}>View More
                        </Link>
                      
                    )}
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>

        <div className="searchBardiv">
          <div id="searchBar" className={`collapse ${webSearch ? "show" : ""}`}>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button type="button" onClick={() => handleSearch(searchQuery)}>
              Search
            </button>
          </div>
        </div>


      </div>

      <div id="sidevar" className="container ">
        <Link to="#" onClick={toggleOpenbar}>
          <div>
            <TfiMenu />
          </div>
        </Link>

        <div className={`pagehead ${AllCarddata.length > 0 ? "pagehead_after" : ""}`}>{mobileHeadingData}</div>

        <div>

          {AllCarddata.length > 0 && (
            <Link to="#" onClick={toggleContainerVisibility} >
              <div style={{ float: "right", padding: "10px 2px 10px 10px" }}>
                {mobSearch ? (
                  <TfiMoreAlt />
                ) : (
                  <TfiMoreAlt />
                )}
              </div>
            </Link>
          )}


          {isContainerVisible && (
            <div className=" card mobile-card-container">
              <div className="row">
                <div className="col-md-12">
                  <li className={`li-text-header ${pillBgColor["hello"] ? "bg-color" : ""}`} onClick={() => {
                    onTabClick("hello", undefined, AlldataData);

                    setShow(false);
                  }}><span className="">All</span></li>
                  {AllCarddata.map((data: any, index: number) => (
                    <li className={`li-text-header ${pillBgColor[data._id] ? "bg-color" : ""}`} onClick={() => {
                      onTabClick(data._id, data.sub_category_name, AlldataData);

                      setShow(true);
                    }} key={index}><span className="">{data.sub_category_name}</span>
                    </li>
                  ))}
                </div>
              </div>
            </div>
          )}

          <Link to="#" onClick={toggleSearch}>
            <div style={{ float: "right", padding: "10px 2px 10px 10px" }} >
              {mobSearch ? (
                // <i className="bi bi-x" onClick={() => setSearchQuery("")}></i>
                <i className="bi bi-x" ></i>
              ) : (
                <i className="bi bi-search hideSearch"></i>
              )}
            </div>
          </Link></div>

      </div>

      <nav
        id="openbar"
        className={`navbar navbar-nav collapse ${isOpenbarOpen ? "show" : ""}`}
      >
        <div className="">
          <ul className="navbar-nav">
            <div id="logo" className="" style={{ paddingTop: "12px" }}>
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <Link to="/">
                  <img src={MicrosoftTeams} alt="Microsoft Teams" />
                </Link>
              </div>
              <div
                className="col-md-3"
                style={{ position: "absolute", right: "5px" }}
              >
                <Link to="#" onClick={toggleOpenbar}>
                  <i className="bi bi-x" style={{ fontSize: "30px" }}></i>
                </Link>
              </div>
            </div>
            <div className="underul mt-2">
              <div id="mobbtn">
                <div id="btn">
                  {isAuthenticated ? (
                    <div>
                      <button
                      className="btn-custom btn1"
                      onClick={()=>{
                        handleProfilebutton();
                        setIsOpenbarOpen(false);
                        toggleOpenbar();
                      }}
                      >
                        Profile
                      </button>
                      <button
                      className="btn-custom btn1"
                      onClick={() => {
                        handleLogoutClick();
                        setIsOpenbarOpen(false);
                        toggleOpenbar();
                      }}
                    >
                      Log Out
                    </button></div>

                  ) : (
                    <button
                      className="btn-custom btn1"

                      onClick={() => {
                        handleLoginClick();

                        setIsOpenbarOpen(false);
                        toggleOpenbar();
                      }}
                    >
                      Log In
                    </button>
                  )}

                  {isAuthenticated ? (
                    ""
                  ) : (
                    <button
                      className="btn-custom btn2"

                      onClick={() => {
                        handleSignupbutton();
                        setIsOpenbarOpen(false);
                        toggleOpenbar();
                      }}
                    >
                      Sign Up
                    </button>
                  )}
                </div>
                <div id="social">
                  <Link to="/">
                    <img
                      src={Facebookpng}
                      alt="Facebook Icon"
                      className="ml-2"
                    />
                  </Link>
                  <Link to="/">
                    <img
                      src={Linkdinpng}
                      alt="LinkedIn Icon"
                      className="ml-2"
                    />
                  </Link>
                  <Link to="/">
                    <img
                      src={Instgrampng}
                      alt="Instagram Icon"
                      className="ml-2"
                    />
                  </Link>
                </div>
              </div>

              <li
                className={`nav-item mt-3 ${location.pathname === "/" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link fontli"
                  to="/"
                  onClick={() => {
                    setIsOpenbarOpen(false);
                    toggleOpenbar();
                  }}
                >
                  Home
                </Link>
              </li>

              <li className={`nav-item ${location.pathname === "/news" ? "active" : ""}`}>
                <Accordion
                  id="news-accordian"
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                  style={{
                    boxShadow: "none",
                    borderRadius: 0,
                    padding: "0px",
                    width: "100%",
                  }}
                >
                  <AccordionSummary className={`nav-item ${location.pathname === "/news" ? "active" : ""}`}
                    expandIcon={<ExpandMoreIcon style={{ color: 'black', fontSize: '2rem' }} />}
                    style={{
                      backgroundColor: "white",
                      borderBottom: "0px solid #ddd",
                      padding: "0px",
                      height: "0px",
                      paddingRight: "10px"
                    }}
                  >
                    <Link
                      className="nav-link"
                      to="/news"
                      onClick={() => {
                        onTabClick("hello", undefined, NewsData);
                        setIsOpenbarOpen(false);
                        toggleOpenbar();
                      }}
                    >
                      <Typography className="fontli">News</Typography>
                    </Link>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "16px" }}>
                    <Typography>
                      {NewsSubData.map((data: any, index: number) => (
                        <li key={index} onClick={() => {
                          onTabClick(data._id, data.sub_category_name, NewsData);
                          setShow(true);
                          toggleOpenbar();
                          navigate("/news")
                        }} className={`liall-item ${index === 0 ? 'lifirst-item' : ''}`}>
                          <span className="ullitext">{data.sub_category_name}</span>
                        </li>
                      ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </li>

              <li className={`nav-item ${location.pathname === "/regulatory" ? "active" : ""}`}>

                <Accordion
                  id="regulatory-accordian"
                  expanded={expanded === 'panel2'}
                  onChange={handleChange('panel2')}
                  style={{
                    boxShadow: "none",
                    borderRadius: 0,
                    padding: "0px",
                    width: "100%",
                  }}
                >

                  <AccordionSummary className={`nav-item ${location.pathname === "/regulatory" ? "active" : ""}`}
                    expandIcon={<ExpandMoreIcon style={{ color: 'black', fontSize: '2rem' }} />}
                    style={{
                      backgroundColor: "white",
                      borderBottom: "0px solid #ddd",
                      padding: "0px",
                      height: "0px",
                      paddingRight: "10px"
                    }}
                  >

                    <Link
                      className="nav-link"
                      to="/regulatory"

                      onClick={() => {
                        onTabClick("hello", undefined, RegulatoryData);
                        setIsOpenbarOpen(false);
                        toggleOpenbar();
                      }}
                    >
                      <Typography className="fontli">Regulatory</Typography>
                    </Link>

                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography>
                      {RegulatorySubdata.map((data: any, index: number) => (
                        <li onClick={() => {
                          onTabClick(data._id, data.sub_category_name, RegulatoryData);
                          setShow(true);
                          toggleOpenbar();
                          navigate("/regulatory")
                        }} key={index} className={`liall-item ${index === 0 ? 'lifirst-item' : ''}`}>
                          <span className="ullitext">{data.sub_category_name}</span>
                        </li>
                      ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </li>

              <li className={`nav-item ${location.pathname === "/gmptraining" ? "active" : ""}`}>
                <Accordion
                  id="gmptraining-accordian"
                  expanded={expanded === 'panel3'}
                  onChange={handleChange('panel3')}
                  style={{
                    boxShadow: "none",
                    borderRadius: 0,
                    padding: "0px",
                    width: "100%",
                  }}
                >
                  <AccordionSummary className={`nav-item ${location.pathname === "/gmptraining" ? "active" : ""}`}
                    expandIcon={<ExpandMoreIcon style={{ color: 'black', fontSize: '2rem' }} />}
                    style={{
                      backgroundColor: "white",
                      borderBottom: "0px solid #ddd",
                      padding: "0px",
                      height: "0px",
                      paddingRight: "10px"
                    }}
                  >
                    <Link
                      className="nav-link"
                      to="/gmptraining"
                      onClick={() => {
                        onTabClick("hello", undefined, GmpTrainingData);
                        setIsOpenbarOpen(false);
                        toggleOpenbar();

                      }}
                    >
                      <Typography className="fontli">GMP Traning</Typography>
                    </Link>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {GmpSubdata.map((data: any, index: number) => (
                        <li onClick={() => {
                          onTabClick(data._id, data.sub_category_name, GmpTrainingData);
                          setShow(true);
                          toggleOpenbar();
                          navigate("/gmptraining")
                        }} key={index} className={`liall-item ${index === 0 ? 'lifirst-item' : ''}`}>
                          <span className="ullitext">{data.sub_category_name}</span>
                        </li>
                      ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </li>

              <li className={`nav-item ${location.pathname === "/business" ? "active" : ""}`}>
                <Accordion
                  id="business-accordian"
                  expanded={expanded === 'panel4'}
                  onChange={handleChange('panel4')}
                  style={{
                    boxShadow: "none",
                    borderRadius: 0,
                    padding: "0px",
                    width: "100%",
                  }}
                >
                  <AccordionSummary className={`nav-item ${location.pathname === "/business" ? "active" : ""}`}
                    expandIcon={<ExpandMoreIcon style={{ color: 'black', fontSize: '2rem' }} />}
                    style={{
                      backgroundColor: "white",
                      borderBottom: "0px solid #ddd",
                      padding: "0px",
                      height: "0px",
                      paddingRight: "10px"
                    }}
                  >
                    <Link
                      className="nav-link"
                      to="/business"
                      onClick={() => {
                        onTabClick("hello", undefined, BusinessData);
                        setIsOpenbarOpen(false);
                        toggleOpenbar();

                      }}
                    >
                      <Typography className="fontli">Products</Typography>
                    </Link>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {BusinessSubData.map((data: any, index: number) => (
                        <li onClick={() => {
                          onTabClick(data._id, data.sub_category_name, BusinessData);
                          setShow(true);
                          toggleOpenbar();
                          navigate("/business")
                        }} key={index} className={`liall-item ${index === 0 ? 'lifirst-item' : ''}`}>
                          <span className="ullitext">{data.sub_category_name}</span>
                        </li>
                      ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </li>

              <li className={`nav-item ${location.pathname === "/product" ? "active" : ""}`}>
                <Accordion
                  id="product-accordian"
                  expanded={expanded === 'panel5'}
                  onChange={handleChange('panel5')}
                  style={{
                    boxShadow: "none",
                    borderRadius: 0,
                    padding: "0px",
                    width: "100%",
                  }}
                >
                  <AccordionSummary className={`nav-item ${location.pathname === "/product" ? "active" : ""}`}
                    expandIcon={<ExpandMoreIcon style={{ color: 'black', fontSize: '2rem' }} />}
                    style={{
                      backgroundColor: "white",
                      borderBottom: "0px solid #ddd",
                      padding: "0px",
                      height: "0px",
                      paddingRight: "10px"
                    }}
                  >
                    <Link
                      className="nav-link"
                      to="/product"
                      onClick={() => {
                        onTabClick("hello", undefined, ProductData);
                        setIsOpenbarOpen(false);
                        toggleOpenbar();

                      }}
                    >
                      <Typography className="fontli">Business</Typography>
                    </Link>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {ProductSubdata.map((data: any, index: number) => (
                        <li onClick={() => {
                          onTabClick(data._id, data.sub_category_name, ProductData);
                          setShow(true);
                          toggleOpenbar();
                          navigate("/product")
                        }} key={index} className={`liall-item ${index === 0 ? 'lifirst-item' : ''}`}>
                          <span className="ullitext">{data.sub_category_name}</span>
                        </li>
                      ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </li>

              <li
                className={`nav-item ${location.pathname === "/video" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link fontli"
                  to="/video"
                  onClick={() => {
                    setIsOpenbarOpen(false);
                    toggleOpenbar();
                  }}
                >
                  Videos
                </Link>
              </li>
              <li
                className={`nav-item ${location.pathname === "/aboutus" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link fontli"
                  to="/aboutus"
                  onClick={() => {
                    setIsOpenbarOpen(false);
                    toggleOpenbar();
                  }}
                >
                  About Us
                </Link>
              </li>
            </div>
          </ul>
        </div>
      </nav>

      <div id="searchdivmob">
        <div
          id="mobSearchBar"
          className={`collapse ${mobSearch ? "show" : ""}`}
        >
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />

          <button type="button" onClick={() => {
            handleSearch(searchQuery);
            dispatch(setMobileHeadingData("Search"))
          }}>Search</button>
        </div>
      </div>


      {isOpenbarOpen ? <div className="b-color"></div> : ""}


    </>
  );
}

export default Header;



