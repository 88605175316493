import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import apiClient from "./FetchCliecnt";

interface NewsState {
  status: 'idle' | 'loading' | 'error';
  error: string;
  NewsData: any[]; 
}
const initialState: NewsState = {
  status: "idle",
  error: "",
  NewsData: [],
};
export const newsData = createAsyncThunk(
  "news/news",
  async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/admin/allnews`
      );
      if (response.data.success) {
        const filteredData = response.data.data.filter((news: any) => news.status === 'active');
        
        return filteredData;
      } else {
        throw new Error('Failed to fetch regulatory data');
      }
    } catch (error: any) { 
      return { success: false, error: error.message }; 
    }
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(newsData.pending, (state) => {
        state.status = 'loading'; 
        state.error = ""; 
      })
      .addCase(newsData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = ""; 
        state.NewsData = action.payload; 
      })
      .addCase(newsData.rejected, (state, action) => {
        state.status = 'error'; 
        state.error = action.error.message ?? "Unknown error"; 
      });
  },
});

export default newsSlice.reducer;
