import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import apiClient from "./FetchCliecnt";

// Define the state interface
interface searchState {
    searchData: any[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

// Initial state
const initialState: searchState = {
    searchData: [],
    status: 'idle',
    error: null,
};


export const getSearchData = createAsyncThunk(
    "search/getSearchData",
    async ( body:{title:string}, thunkAPI) => {
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/homeSearch`,
                body
            );
            if (response) {
                console.log(response.data,"response.data")
                return response.data;
            } else {
                // return thunkAPI.rejectWithValue(response.data.message);
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder
            .addCase(getSearchData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSearchData.fulfilled, (state,action:any) => {
                state.status = 'succeeded';
                state.searchData = action.payload.data;
            })
            .addCase(getSearchData.rejected, (state, action) => {
                state.status = 'failed';
                state.searchData = [];
                state.error = action.payload as string;
            });

    },
});

// export const { login, logout, clear } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default searchSlice.reducer;
