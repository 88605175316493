import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import apiClient from "./FetchCliecnt";


interface ProfileData {
    status: 'idle' | 'loading' | 'error';
    error: string;
    id: string | null;
}

const initialState: ProfileData = {
    status: "idle",
    error: "",
    id: null,
};
export const profileDataUpdate = createAsyncThunk(
  "profile/updateUserProfile",
  async ({ id, fullname }: { id: string; fullname: string }, { rejectWithValue }) => {
      try {
          const [firstname, ...lastnameParts] = fullname.trim().split(" ");
          const lastname = lastnameParts.join(" ") || "";

          const response = await apiClient.put(
              `${process.env.REACT_APP_API_URL}/update-user?id=${id}`,
              { firstname, lastname }
          );          
          if (response.data.success) {
              return response;
          } else {
              return rejectWithValue("Failed to update user profile.");
          }
      } catch (error: any) {
          return rejectWithValue(error.message || "An error occurred.");
      }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(profileDataUpdate.pending, (state, action) => {
        state.status = 'loading';
        state.error = "";
        //state.id = action.meta.arg.id;
      })
      .addCase(profileDataUpdate.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = "";
        //state.ProfileData = [action.payload.data];
        //state.id = action.payload.id;
      })
      .addCase(profileDataUpdate.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error as string ?? "Unknown error";
        //state.id = action.meta.arg.id;
      });
     
  },
});

export default profileSlice.reducer;
