import { configureStore } from '@reduxjs/toolkit';
import authSlice from './Reducers/authSlice';
import aboutSlice from './Reducers/aboutSlice';
import regulatorySlice from './Reducers/regulatorySlice';
import gmpTrainingSlice from './Reducers/gmpTrainingSlice';
import productSlice from './Reducers/productSlice';
import businessSlice from './Reducers/businessSlice';
import newsSlice from './Reducers/newsSlice';
import { businessSubReducer, productSubReducer, gmpSubReducer, regulatorySubReducer, videoSubReducer, newsSubReducer } from './Reducers/cardSlice';
import detailsSlice from './Reducers/detailsSlice';
import { allCardReducer } from './Reducers/allSubcategorySlice';
import allCategorySlice from './Reducers/allCategorySlice';
import disclaimerSlice from './Reducers/disclaimerSlice';
import termsConditionSlice from './Reducers/termsConditionSlice';
import privacySlice from './Reducers/privacySlice';
import pillBgColorSlice from './Reducers/pillBgColorSlice';
import fillerArraySlice from './Reducers/fillerArraySlice';
import { allDataReducer } from './Reducers/allDataSlice';
import videoSlice from './Reducers/videoSlice';
import mobileHeadingSlice from './Reducers/mobileHeadingSlice';
import searchSlice from './Reducers/searchSlice';
import homeSlice from './Reducers/homeSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    about: aboutSlice,
    regulatory: regulatorySlice,
    gmptraining: gmpTrainingSlice,
    product: productSlice,
    business: businessSlice,
    news: newsSlice,
    video:videoSlice,
    newsCard: newsSubReducer,
    businessCard: businessSubReducer,
    productCard: productSubReducer,
    gmpCard: gmpSubReducer,
    regulatoryCard: regulatorySubReducer, 
    videoCard: videoSubReducer,
    allSubCategory: allCardReducer, 
    detailPage: detailsSlice,
    allCategory: allCategorySlice,
    disclaimer: disclaimerSlice,
    temsCondition: termsConditionSlice,
    privacy: privacySlice,
    pillBgColor: pillBgColorSlice,
    fillerArray: fillerArraySlice,
    alldata: allDataReducer,
    mobileHeading: mobileHeadingSlice,
    search: searchSlice,
    home: homeSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
