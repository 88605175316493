import React, { useEffect, useRef, useState } from 'react'
import '../../components/popup/EditPassword.css';
import { Form, Formik, Field, ErrorMessage, FormikHelpers } from "formik";
import passwordImage from "../../assets/Images/password.png";
import eyeopen from "../../assets/Images/eyeopen.svg";
import eyeclosed from "../../assets/Images/eyeclosed.svg";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Loader } from "../loader/Loader";
import { Navigate } from 'react-router-dom';


interface ForgetPasswordUpdateValue {
    newPassword: string;
    confirmPassword: string
}

const EditPassword = () => {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [redirectToHome, setRedirectToHome] = useState(false);
    const formikRef = useRef<any>(null);

    const toggleShowPassword = () => {
        setShowPassword(true);
    };
    const toggleHidePassword = () => {
        setShowPassword(false);
    };
    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(true);
    };
    const toggleHideConfirmPassword = () => {
        setShowConfirmPassword(false);
    };
    useEffect(() => {
        const modal = document.getElementById("exampleModal");

        const handleModalOpen = () => {
            if (formikRef.current) {
                formikRef.current.resetForm();
            }
        };

        if (modal) {
            modal.addEventListener("shown.bs.modal", handleModalOpen);
        }
        return () => {
            if (modal) {
                modal.removeEventListener("shown.bs.modal", handleModalOpen);
            }
        };
    }, []);
    const validationSchema = Yup.object({
        newPassword: Yup.string()
            .min(6, "Password should be at least 6 characters.")
            .max(16, "Password should not exceed 16 characters.")
            .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
            .matches(
                /[!@#$%^&*(),.?":{}|<>]/,
                "Password must contain at least one special character."
            )
            .required("Password is required."),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("newPassword")], "Passwords must match.")
            .required("Confirm password is required."),
    });
    const defaultValue: ForgetPasswordUpdateValue = {
        newPassword: "",
        confirmPassword: ""
    }
    const handleUpdatePassword = async (values: ForgetPasswordUpdateValue, { resetForm }: FormikHelpers<ForgetPasswordUpdateValue>) => {
        const { newPassword, confirmPassword } = values;
        const loginDetail = localStorage.getItem("userInfo")
        const userInfo = loginDetail ? JSON.parse(loginDetail) : {};
        const email = userInfo.email;
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/admin/forgetUserPasswordUpdate`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email,
                        new_password: newPassword,
                        confirm_password: confirmPassword
                    }),
                }
            );
            console.log(response,"response")
            const data = await response.json();
            setLoading(false);
            if (response.ok) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Password updated successfully.",
                });
               document.getElementById("btn-close")?.click();
          
                //setRedirectToHome(true);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to update password. Please try again.",
                });
            }
        } catch (error:any)  {
            setLoading(false);
            console.log(error, "password didn't update");
            Swal.fire({
                icon: "warning",
                title: "Warning",
                text:  error.message || "An error occurred while updating the password.",
            });
            // return;
        } finally {
            resetForm();

        }
    }
    if (redirectToHome) {
        return <Navigate to="/" />;
    }
    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={{ newPassword: '', confirmPassword: '' }}
                validationSchema={validationSchema}
                onSubmit={handleUpdatePassword}
                validateOnChange={false}
                validateOnBlur = {false}
            >
                <Form>
                    <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header custom-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Edit Password</h5>
                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-filed'>
                                        <div className="form_filed-img">
                                            <img src={passwordImage} className="input1-icon user1-icon" alt="Person Icon" />
                                        </div>
                                        <Field
                                            className="regi-input-signup"
                                            type={showPassword ? "text" : "password"}
                                            id="newPassword"
                                            name="newPassword"
                                            placeholder="New Password"
                                        />
                                        <div className="eye-icon-container">
                                            {showPassword ? (
                                                <img
                                                    src={eyeopen}
                                                    className="eye-icon-size"
                                                    onClick={toggleHidePassword}
                                                />
                                            ) : (
                                                <img
                                                    src={eyeclosed}
                                                    className="eye-icon-size"
                                                    onClick={toggleShowPassword}
                                                />
                                            )}
                                        </div>

                                    </div>
                                    <div className="text-danger field-errorMessage mb-3">
                                        <ErrorMessage name="newPassword" />
                                    </div>
                                    <div className="form-filed">
                                        <div className="form_filed-img">
                                            <img
                                                src={passwordImage}
                                                className="input1-icon user1-icon"
                                                alt="Password Icon"
                                            />
                                        </div>
                                        <Field
                                            className="regi-input-signup"
                                            type={showConfirmPassword ? "text" : "password"}
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            placeholder="Confirm Password"

                                        />
                                        <div className="eye-icon-container">
                                            {showConfirmPassword ? (
                                                <img
                                                    src={eyeopen}
                                                    className="eye-icon-size"
                                                    onClick={toggleHideConfirmPassword}
                                                />
                                            ) : (
                                                <img
                                                    src={eyeclosed}
                                                    className="eye-icon-size"
                                                    onClick={toggleShowConfirmPassword}
                                                />
                                            )}
                                        </div>

                                    </div>
                                    <div className="text-danger field-errorMessage mb-3">
                                        <ErrorMessage name="confirmPassword" />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary btn-close-custom" id="btn-close" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary btn-save-custom">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>

            </Formik>
            {loading && <Loader />}

        </>
    )
}

export default EditPassword
