import React, { useEffect, useState } from "react";
import "../../components/Privacy Policy/PrivacyPolicy.css";
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { privacyData } from "../../Reducers/privacySlice";
import { Loader } from "../loader/Loader";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";

 
function PrivacyPolicy() {

  const [loading, setLoading] = useState(true);
  const { PrivacyData } = useSelector((state: RootState) => state.privacy);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

useEffect(() => {
  setLoading(true);
  dispatch(setMobileHeadingData("Privacy Policy"))
  dispatch(privacyData())
  .then(() => {
    setLoading(false);
  })
  .catch((error) => {
    console.error("Error fetching data:", error);
    setLoading(false);
  });
  dispatch(resetAllCardData());
}, [dispatch]);
  return (
<>
    { loading && <Loader/>}
    <div className="container backgroundblur ">
 
      <div
          className="mainHeadAbout"
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "700",
            paddingTop: "10px",
          }}
        >
          <span>Privacy Policy</span>
        </div>
        <div>
          {PrivacyData && PrivacyData?.map((data: any, index: number)=>(
            <div
            key={index}
            className="fontstyl"
            style={{ paddingTop: "10px" }}
            dangerouslySetInnerHTML={{ __html: data.privacy
            }}
          />
          ))}
        </div>
    </div>
    </>);
}

export default PrivacyPolicy;
